import { Box, FormControl, Grid, Typography, MenuItem, Select } from "@mui/material";
import { Link } from 'react-router-dom';
import Button from "../../components/Button";
import React, { useEffect, useState } from 'react';
import { Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import './Signup.css';
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptions } from "../../redux/subscriptionSlice"; 
import { RegisterUser } from "../../apicalls/users";
const Signup = () => {
  const [subscription_id, setSubscriptionType] = useState('');
  const [price, setPrice] = useState('');
  const [saveprice, setSavePrice] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state) => state.subscriptions || []);
  const isAdmin='vendor';
  const onFinish = async (values) => {
    try {
      const response = await RegisterUser({ ...values, subscription_id,isAdmin,price});
      if (response.success) {
        message.success(response.message);
        navigate("/login");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(fetchSubscriptions()); 
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate, dispatch]);

  // Handle Subscription Type Change
  const handleSubscriptionChange = (event) => {
    const value = event.target.value;
    setSubscriptionType(value);
    const selectedSubscription = subscriptions.find(sub => sub._id === value);
    if (selectedSubscription) {
      setSavePrice(`$${selectedSubscription.price.$numberDecimal} /month`);
      setPrice(`${selectedSubscription.price.$numberDecimal}`);
    } else {
      setPrice('');
      setSavePrice('');
    }
  };

  return (
    <div className="landing-page">
      <header className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#e7f4e7' }}>
        <div className="container">
          <Link className="navbar-brand fs-3 fw-bold text-success" to="/">Airbook</Link>
          <div className="d-flex ms-auto">
            <Link to="/login" className="btn btn-outline-success me-2">Login</Link>
            <Link to="/signup" className="btn btn-success">Signup</Link>
          </div>
        </div>
      </header>
      
      <div className="card-container">
        <div className="card">
          <h1 className="text-xl mb-3">Airbook - Signup</h1>
          <hr />
          
          <Form layout="vertical" className="mt-1" onFinish={onFinish}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: "Please input your name!" }]}
                >
                  <Input type="text" className="form-control" />
                </Form.Item>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    { type: 'email', message: "Please input a valid email!" }
                  ]}
                >
                  <Input type="email" className="form-control" />
                </Form.Item>
              </Grid>

              {/* <Grid item xs={12}>
                <Form.Item
                  label="Sub Domain Name"
                  name="subDomain"
                  rules={[
                    { required: true, message: "Please input your sub domain!" },
                    { 
                      pattern: /^[a-z0-9-]{3,63}$/, 
                      message: "Subdomain should only contain lowercase letters, numbers, and hyphens, and be 3-63 characters long!"
                    }
                  ]}
                >
                  <Input type="text" className="form-control" />
                </Form.Item>
              </Grid> */}

              <Grid item xs={12} md={6}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                    { 
                      pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/, 
                      message: "Password must be at least 8 characters, include one uppercase letter, one number, and one special character."
                    }
                  ]}
                >
                  <Input.Password className="form-control" />
                </Form.Item>
              </Grid>

              <Grid item xs={12} md={6}>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={['password']}
                  rules={[
                    { required: true, message: "Please confirm your password!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The two passwords do not match!"));
                      },
                    }),
                  ]}
                >
                  <Input.Password className="form-control" />
                </Form.Item>
              </Grid>

              <Grid item xs={12} md={6}>
                <Form.Item label="Subscription Type" name="subscription_id">
                  <FormControl fullWidth variant="filled">
                    <Select
                      value={subscription_id}
                      onChange={handleSubscriptionChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select One</MenuItem>
                      {Array.isArray(subscriptions) && subscriptions.length > 0 ? (
                        subscriptions.map((subscription) => (
                          <MenuItem key={subscription._id} value={subscription._id}>
                            {subscription.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No subscription available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Form.Item>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" align="left" color="primary" style={{ marginTop: '10px' }}>
                  <strong>Price: {saveprice}</strong>
                  {/* This input field is hidden, but its value is passed during form submission */}
                
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className="flex flex-col mt-3 gap-2">
                  <Button fullWidth title="SIGN UP" type="submit" />
                </div>
              </Grid>
            </Grid>
          </Form>
        </div>
      </div>

      <footer style={{ marginTop: '20px', backgroundColor: '#e7f4e7' }}>
        <div className="container">
          <p className="text-muted">&copy; 2024 Airbook. All rights reserved.</p>
          <div className="d-flex justify-content-center">
            <Link to="#" className="text-muted mx-2">Privacy Policy</Link>
            <Link to="#" className="text-muted mx-2">Terms of Service</Link>
            <Link to="#" className="text-muted mx-2">Contact Us</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Signup;
