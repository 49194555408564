import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveProjectInfo, geprojectDetails, updateProjectInfo } from "../../apicalls/projectinfo";
import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [projectinfoData, setProjectInfoData] = useState(null);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [files, setFiles] = useState({
        brandLogo: null,
        appIcon: null,
        projectLogo: null,
    });

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    // Validation schema
    const checkoutSchema = yup.object().shape({
        projectinfoName: yup.string().required("Required"),
        companyName: yup.string().required("Required"),
        ContactNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Required"),
        projectinfoaddress: yup.string().required("Required"),
        // brandLogo: yup.mixed().required("Required"),
        // appIcon: yup.mixed().required("Required"),
        // projectLogo: yup.mixed().required("Required"),
    });

    const initialValues = {
        projectinfoName: projectinfoData?.projectinfoName || "",
        ContactNumber: projectinfoData?.ContactNumber || "",
        projectinfoaddress: projectinfoData?.projectinfoaddress || "",
        companyName: projectinfoData?.companyName || "",
        brandLogo: null,
        appIcon: null,
        projectLogo: null,
        vendor_id: vendorId,
    };

    const handleFormSubmit = async (values, { setSubmitting }) => {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            formData.append(key, values[key]);
        });

        Object.keys(files).forEach((key) => {
            if (files[key]) {
                formData.append(key, files[key]);
            }
        });

        try {
            if (projectinfoData) {
                await updateProjectInfo(formData);
                message.success("Project info updated successfully!");
            } else {
                await saveProjectInfo(formData);
                message.success("Project info saved successfully!");
            }
            const updatedData = await geprojectDetails(vendorId);
            const existingProjectInfo = updatedData.data.find((projectinfo) => projectinfo.vendor_id === vendorId);
            setProjectInfoData(existingProjectInfo);
        } catch (error) {
            console.error("Error saving project info:", error);
            message.error("Error saving project info. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        const fetchProjectInfoData = async () => {
            try {
                const data = await geprojectDetails(vendorId);
                const existingProjectInfo = data.data.find((projectinfo) => projectinfo.vendor_id === vendorId);
                if (existingProjectInfo) {
                    setProjectInfoData(existingProjectInfo);
                }
            } catch (error) {
                console.error("Error fetching project info data:", error);
            }
        };

        fetchProjectInfoData();
    }, [vendorId]);

    return (
        <Box m="20px">
            <Header title="PROJECT DETAILS" subtitle="Create or Update Project Details" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}>
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Project Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.projectinfoName}
                                name="projectinfoName"
                                error={!!touched.projectinfoName && !!errors.projectinfoName}
                                helperText={touched.projectinfoName && errors.projectinfoName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Company Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyName}
                                name="companyName"
                                error={!!touched.companyName && !!errors.companyName}
                                helperText={touched.companyName && errors.companyName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Contact Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.ContactNumber}
                                name="ContactNumber"
                                error={!!touched.ContactNumber && !!errors.ContactNumber}
                                helperText={touched.ContactNumber && errors.ContactNumber}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Project Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.projectinfoaddress}
                                name="projectinfoaddress"
                                error={!!touched.projectinfoaddress && !!errors.projectinfoaddress}
                                helperText={touched.projectinfoaddress && errors.projectinfoaddress}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>
                        {["Brand Logo", "App Icon", "Project Logo"].map((label, index) => {
                            const key = label.toLowerCase().replace(" ", "");
                            return (
                                <Box mt="20px" key={key}>
                                    <h5>{label}</h5>
                                    <input
                                        type="file"
                                        onChange={(e) => setFiles((prev) => ({ ...prev, [key]: e.target.files[0] }))}
                                        accept="image/*"
                                    />
                                    {touched[key] && errors[key] && <div>{errors[key]}</div>}
                                </Box>
                            );
                        })}
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                                {projectinfoData ? "Update" : "Save"}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
