import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { savePermission } from "../../apicalls/permission";
import { fetchPermissions, setPermissions } from '../../redux/permissionSlice';

const PermissionsPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { user } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const vendorId = user ? user._id : "";
    const [loading, setLoading] = useState(false);

    // Access the first vendor's roles from the permissions state
    const roles = useSelector((state) => {
        const permissions = state.permissions.permissions;
        return permissions && permissions[0] ? permissions[0].roles : [];
    });

    useEffect(() => {
        if (vendorId) {
            dispatch(fetchPermissions(vendorId));
        }
    }, [vendorId, dispatch]);

    // Toggle permission (View/Edit/Delete) for specific menu
    const handleTogglePermission = (roleName, menuName, permission) => {
        const updatedRoles = roles.map((role) =>
            role.role === roleName
                ? {
                      ...role,
                      menus: role.menus.map((menu) =>
                          menu.name === menuName
                              ? {
                                    ...menu,
                                    permissions: {
                                        ...menu.permissions,
                                        [permission]: !menu.permissions[permission], // Toggle the permission
                                    },
                                }
                              : menu
                      ),
                  }
                : role
        );
        dispatch(setPermissions([{ ...roles[0], roles: updatedRoles }]));
    };

    // Prepare the data and save roles to the backend
    const handleSaveRoles = async () => {
        const permissionsData = roles.map(role => ({
            role: role.role,
            menus: role.menus.map(menu => ({
                name: menu.name,
                permissions: menu.permissions
            }))
        }));

        setLoading(true);
        try {
            await savePermission({ roles: permissionsData,vendorId});
            message.success("Permission details saved successfully!");
        } catch (error) {
            console.error("Error saving permission details:", error);
            message.error("Error saving permission details. Please try again.",error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box m="20px">
            <Typography variant="h4" gutterBottom>
                Role-Based Menu Permissions
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Manage permissions for specific menus by role.
            </Typography>

            {/* Add a check to ensure roles is an array and not undefined */}
            {Array.isArray(roles) && roles.length > 0 ? (
                roles.map((role, index) => (
                    <Box key={index} mb="20px">
                        <Typography variant="h6" gutterBottom>
                            Role: {role.role}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: colors.blueAccent[700] }}>
                                        <TableCell style={{ color: "white" }}>Menu</TableCell>
                                        <TableCell style={{ color: "white" }}>View</TableCell>
                                        <TableCell style={{ color: "white" }}>Edit</TableCell>
                                        <TableCell style={{ color: "white" }}>Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {role.menus.map((menu, menuIndex) => (
                                        <TableRow key={menuIndex}>
                                            <TableCell>{menu.name}</TableCell>
                                            {Object.keys(menu.permissions).map((permission) => (
                                                <TableCell key={permission}>
                                                    <Checkbox
                                                        checked={menu.permissions[permission]}
                                                        onChange={() =>
                                                            handleTogglePermission(
                                                                role.role,
                                                                menu.name,
                                                                permission
                                                            )
                                                        }
                                                        color="primary"
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                ))
            ) : (
                <Typography>No roles found. Please try again later.</Typography>
            )}

            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveRoles}
                style={{ marginTop: "20px" }}
                disabled={loading}
            >
                {loading ? "Saving..." : "Save Permissions"}
            </Button>
        </Box>
    );
};

export default PermissionsPage;
