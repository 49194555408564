import { Box, Button, Typography, useTheme, Dialog, DialogActions, DialogContent, TextField, RadioGroup, FormControlLabel, Radio, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { message } from 'antd';
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import DeleteIcon from "@mui/icons-material/Delete";

import { getAgentList, deleteAgent, updateAgent } from "../../apicalls/agent";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [agentData, setAgentData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [currentAgentId, setCurrentAgentId] = useState(null);
    const [currentAgentData, setCurrentAgentData] = useState({});
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [searchQuery, setSearchQuery] = useState("");
    const fetchAgent = async () => {
        try {
            const response = await getAgentList(vendorId);
            if (response.success) {
                setAgentData(response.data);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to fetch agent data");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await deleteAgent(id);
            if (response.success) {
                message.success("Agent deleted successfully");
                fetchAgent();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to delete agent");
            console.error(error);
        }
    };

    const openDeleteConfirmation = (id) => {
        setCurrentAgentId(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setCurrentAgentId(null);
    };

    const confirmDelete = () => {
        if (currentAgentId) {
            handleDelete(currentAgentId);
            handleCloseDeleteDialog();
        }
    };

    const openUpdateDialogHandler = (agent) => {
        setCurrentAgentData(agent);
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setCurrentAgentData({});
    };

    const handleUpdate = async () => {
        try {
            const response = await updateAgent(currentAgentData._id, currentAgentData);
            if (response.success) {
                message.success(response.message);
                fetchAgent();
                handleCloseUpdateDialog();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            
            message.error("Failed to update agent");
            console.error(error);
        }
    };

    const filtereAgents = agentData.filter(agent => {
        return (
            agent.AgentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            agent.Email.toString().includes(searchQuery) ||
          //  tickets.airlinesType.toString().includes(searchQuery) 
           // passport.address.toString().includes(searchQuery) ||  
           //  tickets.ticketNumber.toLowerCase().includes(searchQuery.toLowerCase()) 
          // tickets.passportType.toLowerCase().includes(searchQuery.toLowerCase()) ||
           // passport.passportFee.toString().includes(searchQuery) ||    
           agent.NIDNumber.includes(searchQuery)
        );
     });

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };


    useEffect(() => {
        fetchAgent();
    }, []);

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="AGENT" subtitle="Agent List" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/createagent" className="text-decoration-none text-white">
                            + Create Agent
                        </Link>
                    </Button>
                </Box>
            </Box>
       {/* Search Box */}
       <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search Agents"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": { border: "none" },
                    "& .MuiDataGrid-cell": { borderBottom: "none" },
                    "& .name-column--cell": { color: colors.greenAccent[300] },
                    "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                    "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                    "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                    "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
                }}
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Picture</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">NID No</th>
                                <th scope="col">Company Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtereAgents.length > 0 ?  filtereAgents.map((agent, index) => (
                                <React.Fragment key={agent._id}>
                                    <tr data-bs-toggle="collapse" data-bs-target={`#r${index}`}>
                                        <th scope="row">+</th>
                                        <td>
                                            <img
                                                alt="logo"
                                                width="50px"
                                                height="50px"
                                                src={agent.agent_document_file}
                                            />
                                        </td>
                                        <td>{agent.AgentName}</td>
                                        <td>{agent.Email}</td>
                                        <td>{agent.PhoneNumber}</td>
                                        <td>{agent.NIDNumber}</td>
                                        <td>{agent.CompanyName}</td>
                                    </tr>
                                    <tr className="collapse accordion-collapse" id={`r${index}`} data-bs-parent=".table">
                                        <td colSpan="7">
                                            <b>Bank Name:</b> {agent.BankName}<br />
                                            <b>Branch Name:</b> {agent.BranchName}<br />
                                            <b>Account No:</b> {agent.AccountNumber}<br />
                                            <b>Address:</b> {agent.Address}<br />
                                            <b>User Level:</b> Agent<br />
                                            <b>Status:</b>
                                            {agent.isActive ? "Active" : "Inactive"}
                                            <br />
                                            <b>Actions:</b>
                                            <EditIcon onClick={() => openUpdateDialogHandler(agent)} />&nbsp;
                                            <DeleteIcon
                                           onClick={() => openDeleteConfirmation(agent._id)}
                                            style={{
                                                cursor: 'pointer',
                                                color: 'red',       
                                              }}
                                        />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )) : (
                                <tr>
                                    <td colSpan="9" className="text-center">No  data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogContent>
                    <Typography variant="h6">Are you sure you want to delete this agent?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmDelete} color="primary">Yes</Button>
                    <Button onClick={handleCloseDeleteDialog} color="secondary">No</Button>
                </DialogActions>
            </Dialog>

            {/* Update Agent Dialog */}
            <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
                <DialogContent>
                    <Typography variant="h6">Update Agent</Typography>
                    <TextField
                        label="Name"
                        value={currentAgentData.AgentName || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, AgentName: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        value={currentAgentData.Email || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, Email: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Phone Number"
                        value={currentAgentData.PhoneNumber || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, PhoneNumber: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="NID No"
                        value={currentAgentData.NIDNumber || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, NIDNumber: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Company Name"
                        value={currentAgentData.CompanyName || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, CompanyName: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Bank Name"
                        value={currentAgentData.BankName || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, BankName: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Branch Name"
                        value={currentAgentData.BranchName || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, BranchName: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Account No"
                        value={currentAgentData.AccountNumber || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, AccountNumber: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Address"
                        value={currentAgentData.Address || ''}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, Address: e.target.value })}
                        fullWidth
                        margin="normal"
                    />
                    <Typography variant="body1">Status:</Typography>
                    <RadioGroup
                        row
                        value={currentAgentData.isActive ? 'Active' : 'Inactive'}
                        onChange={(e) => setCurrentAgentData({ ...currentAgentData, isActive: e.target.value === 'Active' })}
                    >
                        <FormControlLabel value="Active" control={<Radio />} label="Active" />
                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate} color="primary">Update</Button>
                    <Button onClick={handleCloseUpdateDialog} color="secondary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Team;
