import { createSlice } from "@reduxjs/toolkit";
import { getPermissionList } from "../apicalls/permission"; 

const PermissionsSlice = createSlice({
    name: "Permission",
    initialState: {
        permissions: [], 
    },
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        },
        addPermission: (state, action) => {
            state.permissions.push(action.payload);
        },
        removePermission: (state, action) => {
            state.permissions = state.permissions.filter(permission => permission.id !== action.payload.id);
        },
        updatePermission: (state, action) => {
            const index = state.permissions.findIndex(permission => permission.id === action.payload.id);
            if (index !== -1) {
                state.permissions[index] = action.payload;
            }
        },
    },
});
export const { setPermissions, addPermission, removePermission, updatePermission } = PermissionsSlice.actions;

export const fetchPermissions = (vendorId) => async (dispatch) => {
    try {
        const response = await getPermissionList(vendorId); 
        dispatch(setPermissions(response.data));
    } catch (error) {
        console.error("Failed to fetch Permissions:", error);
    }
};

export default PermissionsSlice.reducer;
