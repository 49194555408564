const { axiosInstance } = require(".");

//const BASE_URL = 'http://localhost:8000/api/users';
const BASE_URL = 'http://65.1.94.207:8000/api/users';
// Register user
export const RegisterUser= async (payload)=>{
    try {
 const response= await axiosInstance.post(`${BASE_URL}/register`,payload)
  return response.data;
    } catch (error){
         console.log(error)
    }
}
export const LoginUser = async (payload)=>{
  try {
     const response = await axiosInstance.post(`${BASE_URL}/login` , payload)
     return response.data
} catch (error) {
   return error
}
}

//get Current User
export const GetCurrentUser = async () => {
  try {
      const response = await axiosInstance.get(`${BASE_URL}/get-user-data`);
      return response.data;
  } catch (error) {
      return error;
  }
}


//feaching user list data
export const feachUserList = async () => {
  try {
      const response = await axiosInstance.get(`${BASE_URL}/get-user-list`);
      return response.data;
  } catch (error) {
      return error;
  }
}


/**
 * Added by Dilip Yadav 
 * Used for soft deleting an user
 * @param {string} id 
 * @returns {Promise<Object>}
 */
 export const deleteUser = async (id) => {
  try {
      const response = await axiosInstance.delete(`${BASE_URL}/delete-user/${id}`);
      return response.data;
  } catch (error) {
      console.error("Error deleting user:", error);
      throw error; 
  }
};

/**
* Added by Dilip Yadav 
* Used for updating an user's profile
* @param {string} id 
* @param {Object} payload 
* @returns {Promise<Object>}
*/
export const updateUser = async (id, payload) => {
  try {
      const response = await axiosInstance.put(`${BASE_URL}/update-user/${id}`, payload);
      return response.data; // Ensure this returns a structure with `success`, `message`, and possibly `data`
  } catch (error) {
      // Log the error details for debugging
      console.error("Error updating user:", error.response ? error.response.data : error.message);

      // Throw a more descriptive error
      throw new Error(
          error.response && error.response.data
              ? `Error updating user details: ${error.response.data.message}`
              : "Error updating user details"
      );
  }
};

